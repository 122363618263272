<template>
  <v-container fluid class="mt-5">
    <v-row justify="center">
      <v-col cols="11">
        <v-expand-transition>
          <loader v-if="loading" />
          <v-row v-else>
            <CreateAchievement @created="getAchievements" />
            <UpdateAchievement
              :achievementData="selectedAchievement"
              @updated="getAchievements"
            />
            <DeleteAchievement
              :achievementData="selectedAchievement"
              @removed="getAchievements"
            />

            <v-col
              cols="12"
              class="d-flex justify-end"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    height="56px"
                    class="ml-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="createAchievement()"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Cadastrar conquista</span>
              </v-tooltip>
            </v-col>

            <v-col
              lg="3"
              md="4"
              sm="6"
              cols="12"
              v-for="(achievement, index) in achievements"
              :key="achievement._id"
            >
              <v-card>
                <v-btn
                  fab
                  x-small
                  color="error"
                  class="delete-btn"
                  @click="removeAchievement(achievement)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
                <div class="d-flex flex-column flex-no-wrap justify-center align-center pa-2" @click="updateAchievement(achievement)">
                  <v-avatar
                    tile
                    left
                    size="80%"
                    height="100px"
                    color="#F7F7F7"
                    class="achievement-image"
                  >
                    <v-img
                      v-if="achievement.image"
                      :src="achievement.image"
                      contain
                      max-height="60%"
                      @error="imageErrorHandler(index)"
                    />

                    <span
                      v-else
                      class="display-2 white--text text-center"
                    >
                      <v-icon>
                        mdi-trophy-award
                      </v-icon>
                    </span>
                  </v-avatar>
                  <span class="headline mt-1">
                    {{truncate(achievement.title, 20)}}
                  </span>
                  <span class="subtitle-2">
                    {{truncate(achievement.description, 50)}}
                  </span>
                </div>
              </v-card>
            </v-col>

            <v-col
              v-if="achievements.length === 0"
              cols="12"
            >
              <empty-data />
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    Loader: () => import('@/components/LoadingCards'),
    EmptyData: () => import('@/components/EmptyData'),
    CreateAchievement: () => import('./components/CreateAchievement.vue'),
    UpdateAchievement: () => import('./components/UpdateAchievement.vue'),
    DeleteAchievement: () => import('./components/DeleteAchievement.vue')
  },
  data () {
    return {
      loading: false,
      selectedAchievement: {}
    }
  },
  computed: {
    achievements: {
      get () {
        return this.$store.state.achievements
      },
      set (val) {
        this.$store.commit('SET_ACHIEVEMENTS', val)
      }
    },
    createAchievementModal: {
      get () {
        return this.$store.state.createAchievementModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createAchievementModal',
          value: val
        })
      }
    },
    updateAchievementModal: {
      get () {
        return this.$store.state.updateAchievementModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateAchievementModal',
          value: val
        })
      }
    },
    deleteAchievementModal: {
      get () {
        return this.$store.state.deleteAchievementModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'deleteAchievementModal',
          value: val
        })
      }
    }
  },
  mounted () {
    this.getAchievements()
  },
  methods: {
    getAchievements () {
      this.loading = true

      this.$http.get('/achievements')
        .then((res) => {
          const { data } = res.data
          this.achievements = data
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => (this.loading = false))
    },
    createAchievement () {
      this.createAchievementModal = true
    },
    updateAchievement (achievement) {
      this.selectedAchievement = achievement
      this.updateAchievementModal = true
    },
    removeAchievement (achievement) {
      this.selectedAchievement = achievement
      this.deleteAchievementModal = true
    },
    imageErrorHandler (index) {
      this.achievements[index].image = imageOffIcon
    }
  }
}
</script>

<style scoped>
.achievement-image {
  border: 1px solid #BDBDBD !important;
  border-radius: 3px !important;
}

.delete-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 5;
}

.delete-btn:hover {
  transform: scale(1.1);
}
</style>
